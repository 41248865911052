.ColorPicker-InputContainer {
  padding: 1px;
  padding-left: 15px;
  border-radius: 4px;
  margin-right: 0.2rem;
}

.ColorPicker-Input {
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0;
  padding: 9.5px 6px;
  width: 100%;

  &:focus {
    border: none;
    outline: none;
  }
}

.ColorPicker-Swatch {
  margin: 0 0.2rem;

  &:last-of-type {
    margin-right: 0;
  }
}
