@import '~styles/theme';

.LinkRow {
  color: $dark3;
  cursor: pointer;
  display: flex;
  padding: 0.8rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.5s;

  &:hover {
    background-color: $primary;

    .LinkRow-RightArrow {
      color: $light4;
    }
  }
}

.LinkRow-Name {
  margin: 0;
  text-align: left;
}

.LinkRow-RightArrow {
  color: transparent;
  pointer-events: none;
  transition: color 0.5s;
}

.LinkRow-Additional {
  color: $light1;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.LinkRow-AdditionalIcon {
  color: $light2;
}
