@import '~styles/theme';

.Label {
  color: $light4;
  display: block;
  font-weight: 600;
  margin-top: 1.2rem;
  margin-bottom: 0.3rem;

  &--noPadding {
    margin-top: 0rem;
  }
}
