@import '~styles/theme';

.Header {
  color: $light4;
  margin: 1rem 0;
  text-align: center;

  &--dark {
    color: $dark4;
  }

  &--noMargin {
    margin: 0;
  }
}
.Header-1 {
  font-size: 5rem;
  font-weight: 600;
  line-height: 5rem;
}
.Header-2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2rem;
}
.Header-3 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.6rem;
}
.Header-4 {
  font-size: 1.1rem;
  font-weight: 200;
  line-height: 1.2rem;
}
