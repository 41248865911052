@import '~styles/utilities';
@import '~styles/theme';

$hexSize: variableSize(140, 200);
$hexBorder: 1px solid $dark1;

$bg-color: $dark4;
$txt-color: $cyber;
$color-c1: #00b8ff;
$color-c2: #001eff;
$offset-c1: 3;
$offset-c2: 4;
$lay-c1: 3;
$lay-c2: 4;

.hero {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 8rem 0 5rem;
}

.glitch {
  cursor: default;
  display: block;

  &:hover {
    animation: text-bag 4s linear infinite;
    transform: skewX(0deg) scaleY(1);

    .mainHeader {
      &:before {
        left: -(#{$offset-c2}px);
        text-shadow: #{$lay-c2}px 0 $color-c2;
        animation: c2 2s infinite linear alternate-reverse;
      }
      &:after {
        left: #{$offset-c1}px;
        text-shadow: -(#{$lay-c1}px) 0 $color-c1;
        animation: c1 4s infinite linear alternate-reverse;
      }
    }
  }
}

.mainHeader {
  animation: fadein 1.5s;
  color: $cyber;
  display: block;
  font-family: KillTheNoise, sans-serif !important;
  font-size: variableSize(46, 150);
  font-weight: 200;
  line-height: variableSize(35, 90);
  position: relative;

  &:before,
  &:after {
    display: block;
    content: attr(data-text);
    font-size: variableSize(45, 149);
    position: absolute;
    top: 0;
    color: $txt-color;
    background: $bg-color;
    overflow: hidden;
    clip: rect(0, 9999px, 0, 0);
  }
}

.subHeader {
  animation: fadein 1.5s;
  font-size: variableSize(26, 72);
  font-style: italic;
  font-weight: bold;
}

.actions {
  animation: fadein 1.5s;
  margin-top: variableSize(20, 70);
}

.action {
  align-items: center;
  background-color: transparent;
  border: $hexBorder;
  border-radius: 50%;
  color: $light2;
  cursor: pointer;
  display: flex;
  font-size: variableSize(20, 32);
  height: $hexSize;
  justify-content: center;
  margin-right: 5rem;
  outline: none;
  padding: 0;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  width: $hexSize;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: $transparentDark;
    background: radial-gradient($transparentDark, transparent);

    .hexagon {
      animation-duration: 4s;
    }
  }
}

$innerHex: 5px;

.hexagonWrap {
  bottom: $innerHex;
  left: $innerHex;
  position: absolute;
  right: $innerHex;
  text-align: center;
  top: $innerHex;
}

.hexagon {
  animation: spin 8s linear infinite;
  border-bottom: $hexBorder;
  border-top: $hexBorder;
  display: block;
  height: 100%;
  margin: auto;
  width: calc(100% * 0.57735);

  &:before,
  &:after {
    border-bottom: $hexBorder;
    border-top: $hexBorder;
    content: '';
    height: 100%;
    width: 100%;
  }

  &:before {
    position: absolute;
    right: 0;
    transform: rotateZ(-60deg);
  }

  &:after {
    display: block;
    transform: rotateZ(60deg);
  }
}

.sectionHeader {
  align-items: flex-end;
  background: linear-gradient(transparent, transparent, $transparentDark);
  border-bottom: $hexBorder;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  display: flex;
  height: 4rem;
  justify-content: center;
  margin: 0 auto 3rem auto;
  padding-bottom: 2.5rem;
  width: 90%;
}

$grid-width: 20rem;
$grid-height: 12rem;

.grid {
  display: grid;
  grid-auto-rows: $grid-height;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, $grid-width);
  justify-content: center;
  margin-bottom: 8rem;
  margin-top: 1rem;
}

@media (max-width: 1100px) {
  .grid {
    grid-template-columns: repeat(2, $grid-width);
  }
}

@media (max-width: 750px) {
  .grid {
    grid-template-columns: $grid-width;
  }
}

@media (max-width: 500px) {
  .actions {
    flex-direction: column;
  }

  .action {
    margin: 0;
    margin-bottom: 1.5rem;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes c1 {
  @for $i from 0 through 20 {
    #{$i * (1 / 20) * 10 * 10%} {
      clip: rect(#{randomVal(80)}px, 9999px, #{randomVal(80)}px, 0);
    }
  }
}

@keyframes c2 {
  @for $i from 0 through 20 {
    #{$i * (1 / 20) * 10 * 10%} {
      clip: rect(#{randomVal(80)}px, 9999px, #{randomVal(80)}px, 0);
    }
  }
  23% {
    transform: scaleX(0.9);
  }
}

@keyframes text-bag {
  @for $i from 0 through 50 {
    #{$i * (1 / 50) * 10 * 10%} {
      transform: translate(#{randomVal(5)}px, #{randomVal(5)}px);
    }
  }
  1% {
    transform: scaleY(1) skewX(0deg);
  }
  1.5% {
    transform: scaleY(3) skewX(-60deg);
  }
  2% {
    transform: scaleY(1) skewX(0deg);
  }
  51% {
    transform: scaleX(1) scaleY(1) skewX(0deg);
  }
  52% {
    transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
  }
  53% {
    transform: scaleX(1) scaleY(1) skewX(0deg);
  }
}
