.StarField-Container {
  display: flex;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: bg-move 2s linear infinite;
    background-size: 100% 8px;
    background-image: linear-gradient(
      0,
      rgba(255, 255, 255, 0.05) 10%,
      transparent 10%,
      transparent 50%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.05) 60%,
      transparent 60%,
      transparent
    );
  }
}

@keyframes bg-move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -32px;
  }
}
