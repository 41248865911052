@import '~react-redux-toastr/src/styles/index';
@import '~react-select/scss/default';
@import '~styles/theme';
@import '~styles/fonts';

body {
  margin: 0;
  padding: 0;
  background-color: $dark4;
}

* {
  font-family: 'Titillium Web', sans-serif !important;
}

.DefaultIcon g {
  fill: $light2;
}

@media print {
  body {
    background-color: white;
  }
}
