.Checkbox {
  margin-top: 1.2rem;

  & * {
    margin: 0;
  }

  & input {
    height: 1.3rem;
    margin-right: 1rem;
  }
}
