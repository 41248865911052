@import '~styles/theme';

.Button {
  background-color: transparent;
  border: 1px solid $light4;
  border-radius: 4px;
  box-sizing: border-box;
  color: $light4;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  height: 38px;
  letter-spacing: 0.1rem;
  outline: none;
  line-height: 38px;
  margin: 0.4rem 1rem;
  padding: 0 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  transition: all 0.2s;
  font-weight: 400;

  &:disabled,
  &:disabled:hover {
    border-color: $dark1;
    color: $dark1;
  }

  &:hover {
    color: $light2;
    border-color: $light2;
    outline: 0;
  }
}

.Button-Inner {
  pointer-events: none;
}

.Button-Loading {
  visibility: hidden;
}

.Button-Spinner {
  left: calc(50% - 10px);
  position: absolute;
  top: calc(50% - 10px);
}

.Button-Primary {
  background-color: $light4;
  color: $dark4;

  &:hover {
    background-color: $light2;
    border-color: $light2;
    color: $dark4;
  }

  &:disabled,
  &:disabled:hover {
    color: $dark4;
    cursor: default;
    background-color: $dark1;
    border-color: $dark1;
  }
}

.Button-NoMargin {
  margin: 0;
}

.Button-Skinny {
  margin: 0;
  line-height: initial;
  padding: 0.5rem 1rem;
  height: auto;
}

.Button-Minimal {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  color: $light2;
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 400;
  height: 1.5rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  margin: 0.2rem;
  outline: none;
  padding: 0 0.2rem;
  text-decoration: none;
  transition: all 0.2s;
  white-space: nowrap;

  &:hover {
    border-bottom: 1px solid $dark1;
    color: $light3;
  }
}
