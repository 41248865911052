$dark4: #091833;
$dark3: #11203b;
$dark2: #182742;
$dark1: #3c4b66;

$cyber: #d600ff;
$error: #fc3c3c;
$transparentDark: rgba(60, 75, 102, 0.5);
$primary: #863c4e;
$primary-alt: #792f41;

$light1: #8f8f8f;
$light2: #b2b2b2;
$light3: #dbdbdb;
$light4: #e3e3e3;

$google: #d34836;
