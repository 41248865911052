@import '~styles/theme';

.Changelog-Header {
  margin-top: 3rem;
}

.Changelog-Item {
  color: $light2;
  max-width: 36rem;
}

.Changelog-Updates {
  margin-bottom: 0;
  margin-top: 0.3rem;
}

.Changelog-List {
  margin-top: 0.3rem;
}

.Changelog-Change {
  margin-bottom: 0.2rem;
}

.Changelog-Date {
  color: $light1;
  font-size: 1rem;
  margin-left: 0.6rem;
}

.Changelog-Version {
  margin-bottom: 0.5rem;
}

.Changelog-ContributorLink {
  color: $light2;
  margin-left: 0.4rem;
  transition: color 0.2s linear;

  &:hover {
    color: $light3;
  }
}
