@import '~styles/theme';

.Modal {
  background: $dark3;
  border-radius: 0.2rem;
  color: $light2;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.Modal-Overlay {
  align-items: center;
  background-color: rgba(200, 200, 200, 0.3);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.Modal-InnerContainer {
  height: 100%;
}

.Modal-Title {
  margin: 0;
}

.Modal-Close {
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: $light3;
  }
}

.Modal-Section {
  padding: 1rem;
}

.Modal-Header {
}

.Modal-Content {
  background-color: $dark2;
  flex: 1;
  padding: 1.5rem 1rem;
}

.Modal-Footer {
}

.Model-FooterButton {
  margin-right: 0;
}
