@import '~styles/theme';

.container {
  color: $light2;
  font-size: 0.9rem;
  padding: 0.2rem 0 0.5rem;

  &--vertical {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    .LabeledItem-Label {
      padding: 0;
    }
  }
}

.label {
  margin: 0;
  padding-right: 0.5rem;
  text-align: left;
}

.item {
  flex: 1;
  white-space: pre-line;
  width: 100%;
}
