@import '~styles/theme';

@keyframes circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.Spinner {
  position: relative;

  &--dark .Spinner-Child:before {
    background-color: $dark4;
  }
}

.Spinner-Child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $light2;
    border-radius: 100%;
    animation: circleBounceDelay 1.2s infinite ease-in-out both;
  }
}

.Spinner-Circle2 {
  transform: rotate(30deg);
}
.Spinner-Circle3 {
  transform: rotate(60deg);
}
.Spinner-Circle4 {
  transform: rotate(90deg);
}
.Spinner-Circle5 {
  transform: rotate(120deg);
}
.Spinner-Circle6 {
  transform: rotate(150deg);
}
.Spinner-Circle7 {
  transform: rotate(180deg);
}
.Spinner-Circle8 {
  transform: rotate(210deg);
}
.Spinner-Circle9 {
  transform: rotate(240deg);
}
.Spinner-Circle10 {
  transform: rotate(270deg);
}
.Spinner-Circle11 {
  transform: rotate(300deg);
}
.Spinner-Circle12 {
  transform: rotate(330deg);
}
.Spinner-Circle2:before {
  animation-delay: -1.1s;
}
.Spinner-Circle3:before {
  animation-delay: -1s;
}
.Spinner-Circle4:before {
  animation-delay: -0.9s;
}
.Spinner-Circle5:before {
  animation-delay: -0.8s;
}
.Spinner-Circle6:before {
  animation-delay: -0.7s;
}
.Spinner-Circle7:before {
  animation-delay: -0.6s;
}
.Spinner-Circle8:before {
  animation-delay: -0.5s;
}
.Spinner-Circle9:before {
  animation-delay: -0.4s;
}
.Spinner-Circle10:before {
  animation-delay: -0.3s;
}
.Spinner-Circle11:before {
  animation-delay: -0.2s;
}
.Spinner-Circle12:before {
  animation-delay: -0.1s;
}
