@import '~styles/theme';

.Configure-PaddedButtons {
  margin-top: 2rem;
}

.Configure-Info {
  color: $light1;
}

.Configure-ManageContainer {
  width: 100%;
}

.Configure-ManageLink {
  color: $light1;
  margin-bottom: 0;
}
