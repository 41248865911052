@import '~styles/theme';

.Dropdown {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.1rem;
  position: relative;
}

.Dropdown-Icon {
  color: $dark1;
  cursor: pointer;
  padding: 11px 11px 8px;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.2s;

  &:hover {
    color: $dark3;
  }
}

.Select.Dropdown-Select {
  .Dropdown-Option {
    font-size: 12px;
  }

  &.Select--multi .Select-control .Select-value {
    color: $dark4;
    line-height: 20px;
  }

  &.Dropdown-Select--icon {
    .Select-input > input {
      padding-left: 30px;
    }

    .Select-value,
    .Select-placeholder {
      padding-left: 40px;
    }
  }

  &.Dropdown-Select--up {
    .Select-menu-outer {
      bottom: 100%;
      top: auto;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.is-open > .Select-control {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &.is-open .Select-control .Select-arrow {
    border-color: transparent transparent $dark1;
  }

  .Select-control {
    background-color: $light4;
    height: 38px;

    &:hover {
      cursor: pointer;
    }

    .Select-placeholder {
      color: $dark4;
      font-size: 12px;
    }

    .Select-value {
      color: $dark4;
      line-height: 35px;
    }

    .Select-value-label {
      font-size: 12px;
    }

    .Select-arrow {
      border-color: $dark1 transparent transparent;
    }

    .Select-input > input {
      color: $dark4;
    }
  }
}
