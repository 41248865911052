@import '~styles/theme';

.IconInput {
  flex: 1;
  position: relative;
  width: 100%;
}

.IconInput-Input {
  padding-left: 40px;
}

.IconInput-Icon {
  color: $dark1;
  position: absolute;
  left: 12px;
  top: 11px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: $dark3;
  }
}
