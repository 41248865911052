@import '~styles/theme';

.SectionHeader {
  color: $light4;
  border-bottom: 1px solid $light1;
  margin: 1rem;
  padding: 0.5rem 0.3rem;
  width: calc(100% - 2.6rem);
}

.SectionHeader-Inner {
  flex: 1;
  font-size: 1.3rem;
  font-weight: 200;
  margin: 0 0 0 0.2rem;
  text-align: left;
}

.SectionHeader-Icon {
  cursor: pointer;
}

.SectionHeader-AddButton {
  margin: 0;
}

.SectionHeader-Additional {
  color: $light1;
  font-size: 1rem;
}
